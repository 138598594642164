<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-button
                @click="getDivisi()"
                variant="primary"
                v-b-modal.modal-input
                ><CIcon name="cil-plus" />
                {{ $store.state.table.tambahModal }}</b-button
              >
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12" md="12" lg="12">
              <hr />
            </b-col>
          </b-row>

          <b-alert dismissible fade :show="showing" :variant="variant">{{
            msg
          }}</b-alert>

          <b-row>
            <b-col md="3">
              <b-form-group
                :label="$store.state.table.perHalaman"
                label-for="per-page-select"
                label-cols-md="6"
                label-align-md="left"
                label-size="md"
                style="background-color: "
              >
                <b-form-select
                  id="per-page-select"
                  v-model="perPage"
                  :options="pageOptions"
                  size="md"
                ></b-form-select>
              </b-form-group>
            </b-col>

            <b-col md="6" offset-md="3">
              <b-form-group
                :label="$store.state.table.cari"
                label-for="filter-input"
                label-cols-md="3"
                label-align-md="right"
                label-size="md"
              >
                <b-input-group size="md">
                  <b-form-input
                    id="filter-input"
                    v-model.lazy="filter"
                    type="search"
                    :placeholder="$store.state.table.cariHolder"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-button
                      :disabled="!filter"
                      @click="filter = ''"
                      variant="danger"
                      >{{ $store.state.table.hapusCari }}</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col cols="12" md="12" lg="12">
              <b-table
                :items="items"
                :fields="fields"
                :current-page="currentPage"
                :per-page="perPage"
                :filter="filter"
                :filter-included-fields="filterOn"
                stacked="md"
                responsive
                show-empty
                small
                @filtered="onFiltered"
                bordered
                striped
                hover
                :busy="tableBusy"
              >
                <template #cell(actions)="item">
                  <b-button
                    variant="warning"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Edit Data'"
                    v-b-modal.modal-edit
                    @click="(data = item.item), (edit = !edit)"
                    ><CIcon name="cil-pencil" /> {{ item.actions }}</b-button
                  >

                  <b-button
                    variant="danger"
                    class="mr-1"
                    v-c-tooltip.hover.click="'Hapus Data'"
                    v-b-modal.modal-delete
                    @click="data = item.item"
                    ><CIcon name="cil-trash" /> {{ item.actions }}</b-button
                  >
                </template>
              </b-table>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="5" offset-md="7">
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                align="fill"
                size="sm"
              ></b-pagination>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-container>
    <modal-input
      @alertFromChild="triggerAlert($event), getDatas()"
      :divisi="divisi"
    />
    <modal-edit
      :data="data"
      :divisi="divisi"
      :edit="edit"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
    <modal-delete
      :data="data"
      @alertFromChild="triggerAlert($event), getDatas()"
    />
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import modalInput from "./modalInput.vue";
import modalEdit from "./modalEdit.vue";
import modalDelete from "./modalDelete.vue";

export default {
  components: {
    modalInput,
    modalEdit,
    modalDelete,
  },
  name: "posisi",
  data() {
    return {
      showing: false,
      variant: "success",
      msg: "",
      data: "",
      divisi: "",
      edit: false,
      items: [
        {
          no: "-",
          divisi: "-",
          posisi: "-",
          kode: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        // {
        //   key: "masterPosisiId",
        //   label: "UID",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-center",
        // },
        {
          key: "kodePosisi",
          label: "Kode",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaDivisi",
          label: "Divisi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaPosisi",
          label: "Posisi",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },

        {
          key: "actions",
          label: "Actions",
          class: "table-option-2 text-center",
        },
      ],
      options: [
        {
          tipe: "Tetap",
          pilihan: [
            { value: 1, text: "Komisaris" },
            { value: 2, text: "Direksi" },
            { value: 3, text: "Manajer - Tetap" },
            { value: 4, text: "Kasie - Tetap" },
            { value: 5, text: "Staf - Tetap" },
            { value: 6, text: "Mekanik - Tetap" },
            { value: 7, text: "Pengemudi - Tetap" },
            { value: 8, text: "Kebersihan & Taman - Tetap" },
          ],
        },
        {
          tipe: "Kontrak",
          pilihan: [
            { value: 11, text: "Manajer - Kontrak" },
            { value: 12, text: "Staf - Kontrak" },
            { value: 13, text: "Supervisi & Koordinator Cabang - Kontrak" },
            { value: 14, text: "Operator Telepon - Kontrak" },
            { value: 15, text: "Mekanik - Kontrak" },
            { value: 16, text: "Pengemudi - Kontrak" },
            { value: 17, text: "Pesuruh - Kontrak" },
            { value: 18, text: "Petugas Kebersihan - Kontrak" },
            { value: 19, text: "Petugas Parkir & Koordinator - Kontrak" },
            { value: 20, text: "Petugas Taman - Kontrak" },
            { value: 21, text: "Satpam - Kontrak" },
          ],
        },
        {
          tipe: "Borongan",
          pilihan: [{ value: 22, text: "Petugas Kebersihan - Borongan" }],
        },
        {
          tipe: "Bank Jateng",
          pilihan: [
            { value: 31, text: "Call Center - Bank Jateng" },
            { value: 32, text: "Mekanik - Bank Jateng" },
            { value: 33, text: "Pengemudi - Bank Jateng" },
            { value: 34, text: "Pesuruh - Bank Jateng" },
            { value: 35, text: "Satpam - Bank Jateng" },
          ],
        },
        {
          tipe: "Mitra",
          pilihan: [{ value: 41, text: "Satpam - Mitra" }],
        },
      ],

      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length;
    this.getDatas();
    this.getDivisi();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getDivisi() {
      let vm = this;

      axios
        .get(ipBackend + "masterDivisi/list")
        .then((res) => {
          vm.divisi = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getDatas() {
      this.tableBusy = true;
      let listPosisi = await axios.get(ipBackend + "masterPosisi/list");
      this.items = listPosisi.data.data;
      // console.log(this.items, "ini posisisisisisi");
      // this.items = listPosisi.data.data.sort((a, b) => a.kodePosisi - b.kodePosisi);
      // FOREACH diganti FOR
      // this.items.forEach((element, index) => {
      //   let x = this.items[index];
      //   x.no = index + 1;
      // });

      for (let i = 0; i < this.items.length; i++) {
        let x = this.items[i];
        x.no = i + 1;

        for (let a = 0; a < this.options.length; a++) {
          const elA = this.options[a];
          for (let b = 0; b < elA.pilihan.length; b++) {
            const elB = elA.pilihan[b];
            if (elB.value == x.kodePosisi) {
              x.namaKode = elB.text;
            }
          }
        }
      }
      this.totalRows = this.items.length;
      this.tableBusy = false;
    },
    triggerAlert(event) {
      let vm = this;
      // vm.showing = event.showing;
      // vm.variant = event.variant;
      // vm.msg = event.msg;
      vm.getDatas();
      // setTimeout(() => {
      //   vm.showing = false;
      // }, 4000);
      vm.$store.commit("set_alert", event);
    },
  },
};
</script>
