<template>
  <div>
    <b-container fluid="xl">
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark" fluid>
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>Data Bagian & Divisi & Posisi</strong>
              </h5>
            </template>

            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-tabs content-class="mt-3" align="center">
                  <b-tab title="Divisi" active>
                    <Divisi />
                  </b-tab>
                  <b-tab title="Posisi">
                    <Posisi />
                  </b-tab>
                </b-tabs>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Divisi from "@/component/hcms/master/divisiNew/divisi/divisi";
import Posisi from "@/component/hcms/master/divisiNew/posisi/posisi";
export default {
  components: {
    Divisi,
    Posisi,
  },
  name: "divisi",
  data() {
    return {};
  },
};
</script>
