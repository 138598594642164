<template>
  <div>
    <b-modal
      id="modal-input"
      size="lg"
      centered
      title="Tambah Data Master Posisi"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label="Kode Posisi" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('kodePosisi')"
            v-model="$v.data.kodePosisi.$model"
          ></b-form-input>
        </b-form-group>
        <b-form-group label="Divisi" label-cols-md="3">
          <multiselect
            :state="checkIfValid('masterDivisiId')"
            v-model="$v.data.masterDivisiId.$model"
            :options="divisi"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaDivisi"
            track-by="id"
            @input="itikiwir(data.masterDivisiId)"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Posisi" label-cols-md="3">
          <b-form-input
            type="text"
            :state="checkIfValid('namaPosisi')"
            v-model="$v.data.namaPosisi.$model"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <!-- <img :src="src1" /> -->
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
          Tutup
        </b-button>
        <b-button
          variant="primary"
          :disabled="busy || !isValid"
          @click="simpan()"
        >
          {{ button }}
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
export default {
  name: "modalInput",
  props: ["divisi"],
  components: { Multiselect },

  data() {
    return {
      data: {
        kodePosisi: "",
        namaPosisi: "",
        masterDivisiId: "",
      },
      busy: false,
      button: "Simpan",
      // isTetap: false,
      // isKontrak: false,
      // isBorongan: false,
      // isBankJateng: false,
      // isMitra: false,
      // options: [
      //   {
      //     tipe: "Tetap",
      //     pilihan: [
      //       { value: 1, text: "Komisaris" },
      //       { value: 2, text: "Direksi" },
      //       { value: 3, text: "Manajer - Tetap" },
      //       { value: 4, text: "Kasie - Tetap" },
      //       { value: 5, text: "Staf - Tetap" },
      //       { value: 6, text: "Mekanik - Tetap" },
      //       { value: 7, text: "Pengemudi - Tetap" },
      //       { value: 8, text: "Kebersihan & Taman - Tetap" },
      //     ],
      //   },
      //   {
      //     tipe: "Kontrak",
      //     pilihan: [
      //       { value: 11, text: "Manajer - Kontrak" },
      //       { value: 12, text: "Staf - Kontrak" },
      //       { value: 13, text: "Supervisi & Koordinator Cabang - Kontrak" },
      //       { value: 14, text: "Operator Telepon - Kontrak" },
      //       { value: 15, text: "Mekanik - Kontrak" },
      //       { value: 16, text: "Pengemudi - Kontrak" },
      //       { value: 17, text: "Pesuruh - Kontrak" },
      //       { value: 18, text: "Petugas Kebersihan - Kontrak" },
      //       { value: 19, text: "Petugas Parkir & Koordinator - Kontrak" },
      //       { value: 20, text: "Petugas Taman - Kontrak" },
      //       { value: 21, text: "Satpam - Kontrak" },
      //     ],
      //   },
      //   {
      //     tipe: "Borongan",
      //     pilihan: [
      //       { value: 22, text: "Petugas Kebersihan" }
      //     ],
      //   },
      //   {
      //     tipe: "Bank Jateng",
      //     pilihan: [
      //       { value: 31, text: "Call Center - Bank Jateng" },
      //       { value: 32, text: "Mekanik - Bank Jateng" },
      //       { value: 33, text: "Pengemudi - Bank Jateng" },
      //       { value: 34, text: "Pesuruh - Bank Jateng" },
      //       { value: 35, text: "Satpam - Bank Jateng" },
      //     ],
      //   },
      //   {
      //     tipe: "Mitra",
      //     pilihan: [{ value: 41, text: "Satpam - Mitra" }],
      //   },
      // ],
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    data: {
      kodePosisi: {
        required,
      },
      namaPosisi: {
        required,
      },
      masterDivisiId: {
        required,
      },
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {
      // console.log(x);
    },
    simpan() {
      let vm = this;
      vm.busy = true;
      vm.button = "Mohon Tunggu";
      vm.data.masterDivisiId = vm.data.masterDivisiId.masterDivisiId;
      // vm.data.kodePosisi = vm.data.kodePosisi.value
      axios
        .post(ipBackend + "masterPosisi/register", vm.data)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "success",
              msg: "BERHASIL MENDAFTARKAN MASTER POSISI",
              showing: true,
            });
            this.data.namaPosisi = "";
            this.$bvModal.hide("modal-input");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alertFromChild", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alertFromChild", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
